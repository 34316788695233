import registerComponents from '@/components/Renovation'
import '@/style/index.less'
import VueCompositionApi from '@vue/composition-api'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'swiper/dist/css/swiper.css'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import App from './App.vue'
import './components'
import './icons'
import './permission'
import busPlugin from './plugins/bus'
import './plugins/directive'
import router from './router'
import store from './store/index'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueClipboard from 'vue-clipboard2'
import VueAMap from 'vue-amap'

Vue.use(VueAMap)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueAwesomeSwiper)
registerComponents(Vue)
Vue.use(VueCompositionApi)
Vue.use(busPlugin)
Vue.use(Antd)
Vue.use(Viewer)
VueAMap.initAMapApiLoader({
  key: 'fba3933be5d2530e209cc469f3573727',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'Amap Geocoder', 'AMap.ToolBar', 'AMap.Geolocation'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
